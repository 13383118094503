import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer className="flex-initial w-full bg-dbgcolor-light dark:bg-dbgcolor-dark">
            <div className="flex flex-col text-center items-center gap-3 max-w-screen-xl mx-auto p-6 sm:flex-row sm:justify-between">
                <div className="flex flex-row justify-center w-80 gap-4 sm:justify-start sm:gap-8">
                    <Link
                        className="text-md transition-colors hover:text-accent-light dark:hovertext-accent-dark sm:text-lg"
                        to="/"
                    >
                        Home
                    </Link>
                    <a
                        className="text-md transition-colors hover:text-accent-light dark:hovertext-accent-dark sm:text-lg"
                        href="https://zanety.de/imprint"
                    >
                        Impressum
                    </a>
                </div>
                <div className="text-md sm:w-full sm:text-lg">
                    Copyright © {new Date().getFullYear()} by zane
                </div>
                <div className="flex flex-row justify-center w-80 gap-4 sm:justify-end sm:gap-8">
                    {/* <a
                        className="text-md transition-colors hover:text-accent-light dark:hovertext-accent-dark sm:text-lg"
                        href="https://map.zanecraft.net"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Map
                    </a> */}
                    <a
                        className="text-md transition-colors hover:text-accent-light dark:hovertext-accent-dark sm:text-lg"
                        href="/world/zanecraft.zip"
                        target="_blank"
                        rel="noreferrer"
                    >
                        World
                    </a>
                </div>
            </div>
        </footer>
    );
}
