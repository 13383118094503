import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import "./index.css";

function Redirect(href: string): React.FC {
    return () => {
        window.location.href = href;
        return null;
    };
}

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<App />} />
            <Route index element={<App />} />
            <Route
                path="donate"
                Component={Redirect("https://whop.com/zanecraftpremium")}
            />
            <Route
                path="plus"
                Component={Redirect("https://whop.com/zanecraftplus")}
            />
            <Route
                path="premium"
                Component={Redirect("https://whop.com/zanecraftpremium")}
            />
            <Route
                path="zanety"
                Component={Redirect("https://zanety.de")}
            />
            <Route
                path="discord"
                Component={Redirect("https://discord.gg/zane")}
            />
            <Route
                path="youtube"
                Component={Redirect("https://youtube.com/zanety")}
            />
            <Route
                path="spotify"
                Component={Redirect("https://open.spotify.com/playlist/0jcacc6AKk4TiDYlbXrxzN")}
            />
            <Route
                path="imprint"
                Component={Redirect("https://zanety.de/imprint")}
            />
            <Route
                path="*"
                Component={Redirect("/")}
            />
        </Routes>
    </BrowserRouter>
);
