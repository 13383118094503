import { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";

const startDate = new Date('2024-12-08T14:00:00Z');

interface Duration {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

function calcTimeLeft(): Duration | undefined {
    const now = new Date();
    const difference = startDate.getTime() - now.getTime();

    if (difference > 0) {
        return {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
    }
}

function formatDuration({days, hours, minutes, seconds}: Duration): string {
    return `${days}d\u00A0${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
}

export default function App() {
    const [countdown, setCountdown] = useState<Duration | undefined>(calcTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => setCountdown(calcTimeLeft()), 1000);
        return () => clearTimeout(timer);
    }, [countdown]);

    return (
        <>
            <Header />
            <main className="flex flex-col flex-auto bg-bgcolor-light text-black dark:bg-bgcolor-dark dark:text-white">
                <div
                    className="h-screen bg-center bg-cover"
                    style={{ backgroundImage: "url('/images/background.jpg')" }}
                >
                    <div className="absolute bottom-1/2 md:right-1/2 md:translate-x-1/2 translate-y-1/2 w-[1024px] max-w-full p-2 pt-20 overflow-hidden">
                        <img
                            className="m-auto mb-2 md:mb-4"
                            src="/images/zanecraft2.png"
                            alt="Zanecraft 2"
                        />
                        <p className="m-auto mb-2 font-display font-bold text-3xl text-white text-center drop-shadow-[0_0_5px_rgba(0,0,0,0.8)] sm:text-5xl md:mb-5 md:text-6xl">
                            {countdown ?
                                <>Start in {formatDuration(countdown)}!</> :
                                <>Viel Spaß bei Staffel&nbsp;2!'</>}
                        </p>
                        <p className="m-auto drop-shadow-[0_0_5px_rgba(0,0,0,0.8)] text-xl text-white text-center sm:text-3xl md:text-4xl">
                            Nur auf <a className="underline" href="https://discord.gg/zane" target="_blank" rel="noreferrer" >discord.gg/zane</a>
                        </p>
                    </div>
                </div>
                <article className="flex flex-col gap-4 max-w-screen-xl mx-auto p-4 sm:gap-6 sm:p-6">
                    <div>
                        <h1 className="mb-6 font-display text-4xl font-bold sm:mb-8 sm:text-6xl lg:text-7xl">
                            Zanecraft 2
                        </h1>
                        <h2 className="mb-4 font-display text-2xl font-bold sm:mb-6 sm:text-3xl lg:text-4xl">
                            Infos
                        </h2>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            <strong>Zanecraft&nbsp;2</strong> ist der Nachfolger der erfolgreichen ersten Staffel von Zanecraft.
                            Es ist ein <strong>1.21 Minecraft-Projekt</strong>, das an <strong>Craft Attack</strong> orientiert ist.
                        </p>
                        <h3 className="mb-1 font-display text-xl font-bold sm:mb-2 sm:text-xl lg:text-2xl">
                            ☑️ » Teilnahme
                        </h3>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            So kannst du an Zanecraft <strong>teilnehmen</strong>:
                        </p>
                        <ul className="list-disc pl-4 mb-2 text-lg sm:mb-4 sm:text-xl">
                            <li>Lies dir hier oder in <strong>#info</strong> auf Discord alles durch</li>
                            <li><strong>Akzeptiere</strong> die Regeln in <strong>#rules</strong> auf Discord</li>
                            <li>Sende deinen <strong>Minecraft-Namen</strong> in <strong>#whitelist</strong> (für Bedrock mit <code>bedrock:</code> davor)</li>
                        </ul>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            Daraufhin wirst du automatisch zur <strong>Whitelist</strong> des Servers hinzugefügt.
                            Pro Discord Account kann nur <strong>ein Minecraft Account</strong> gewhitelistet werden.
                        </p>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            Danach kannst du den Server jederzeit mit der <strong>Java Edition</strong> bzw. <strong>Bedrock Edition</strong> über die Adresse <code className="underline">zanecraft.net</code> betreten.
                            Wir haften <strong>nicht</strong> für Bugs auf der <strong>Bedrock Edition</strong>!
                        </p>
                        <h3 className="mb-1 font-display text-xl font-bold sm:mb-2 sm:text-xl lg:text-2xl">
                            🚩 » Spawn
                        </h3>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            Das Zentrum der Welt ist der <strong>Shopping District</strong> mit dem <strong>Spawn</strong>, zu dem du jederzeit per Command <strong>zurückkehren</strong> kannst.
                            Im Spawnbereich können <strong>keine Blöcke</strong> verändert werden.
                        </p>
                        <h3 className="mb-1 font-display text-xl font-bold sm:mb-2 sm:text-xl lg:text-2xl">
                            🏠 » Plots
                        </h3>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            Im Shopping District gibt es sogenannte <strong>Plots</strong>, die du mit <strong>Diamanten</strong> kaufen kannst.
                            Du kannst anderen Spielern <strong>Rechte</strong> auf deinen Plots geben.
                            Ansonsten kann auf deinen Plots <strong>niemand</strong> Blöcke verändern.
                        </p>
                        <h3 className="mb-1 font-display text-xl font-bold sm:mb-2 sm:text-xl lg:text-2xl">
                            💎 » Shops
                        </h3>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            Auf deinen Plots kannst du <strong>eigene Shops</strong> errichten, indem du Villager mit <strong>beliebigen Trades</strong> konfigurierst.
                            Mit diesen Villagern können andere Spieler <strong>Items</strong> von dir kaufen.
                        </p>
                        <h3 className="mb-1 font-display text-xl font-bold sm:mb-2 sm:text-xl lg:text-2xl">
                            🔨 » Areas
                        </h3>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            Du kannst in der Overworld und im Nether <strong>Areas</strong> für Basen und Farmen <strong>reservieren</strong>.
                            Innerhalb dieser Areas kann <strong>niemand</strong> Blöcke verändern, außer den Spielern, denen du <strong>Rechte</strong> gegeben hast.
                        </p>
                        <h3 className="mb-1 font-display text-xl font-bold sm:mb-2 sm:text-xl lg:text-2xl">
                            ➕ » Sonstiges
                        </h3>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            Standardmäßig ist <strong>PvP</strong> deaktiviert, du kannst es aber jederzeit für dich aktivieren.
                            Das <strong>End</strong> wird erst zu einem <strong>späteren</strong> Zeitpunkt für alle geöffnet.
                        </p>
                        <p className="font-display text-lg font-bold sm:text-xl">
                            Alle weiteren Infos auf <a className="underline" href="https://discord.gg/zane" target="_blank" rel="noreferrer" >discord.gg/zane</a>!
                        </p>
                        <hr className="my-6 opacity-80 sm:my-8"/>
                        {/* -------------------------------------------------------------------------------- */}
                        <h2 className="mb-4 font-display text-2xl font-bold sm:mb-6 sm:text-3xl lg:text-4xl">
                            Regeln
                        </h2>
                        <h3 className="mb-1 font-display text-xl font-bold sm:mb-2 sm:text-xl lg:text-2xl">
                            📘 » Grundregeln
                        </h3>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            Alle allgemeinen Regeln in <strong>#rules</strong> auf <a className="underline" href="https://discord.gg/zane" target="_blank" rel="noreferrer" >discord.gg/zane</a> müssen befolgt werden.
                        </p>
                        <h3 className="mb-1 font-display text-xl font-bold sm:mb-2 sm:text-xl lg:text-2xl">
                            §1 » Griefing
                        </h3>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            <strong>Diebstahl</strong> und <strong>Griefing</strong> ist strengstens verboten und wird mit einem <strong>permanenten Bann</strong> bestraft!
                        </p>
                        <h3 className="mb-1 font-display text-xl font-bold sm:mb-2 sm:text-xl lg:text-2xl">
                            §2 » Verunstalten
                        </h3>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            Die Platzierung <strong>zufälliger Blöcke</strong>, das <strong>unvollständige Abbauen</strong> von Strukturen und das <strong>Verunstalten</strong> der Welt ist verboten.
                        </p>
                        <h3 className="mb-1 font-display text-xl font-bold sm:mb-2 sm:text-xl lg:text-2xl">
                            §3 » PvP
                        </h3>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            Einem anderen Spieler <strong>Schaden zuzufügen</strong> oder ihn zu <strong>töten</strong> ist nur nach Absprache mit diesem Spieler zulässig.
                            Nach jedem Kampf müssen <strong>alle Items</strong> zurückgegeben werden.
                        </p>
                        <h3 className="mb-1 font-display text-xl font-bold sm:mb-2 sm:text-xl lg:text-2xl">
                            §4 » Modifikationen
                        </h3>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            Das Betreten des Servers mit einer <strong>verbotenen Modifikation</strong> ist strengstens untersagt.
                            Erlaubte Modifikationen sind <strong>ausschließlich</strong>:
                            </p>
                        <ul className="list-disc pl-4 mb-2 text-lg sm:mb-4 sm:text-xl">
                            <li>Performance Mods, z.B. Optifine und Sodium</li>
                            <li>Visuelle Mods, z.B. Replay Mod, Litematica und Shader</li>
                            <li>Custom Clients, z.B. LabyMod, Badlion, Lunar und Feather</li>
                        </ul>
                        <h3 className="mb-1 font-display text-xl font-bold sm:mb-2 sm:text-xl lg:text-2xl">
                            §5 » Fairness
                        </h3>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            Es ist nicht erlaubt, sich durch Tools einen <strong>unfairen Vorteil</strong> zu verschaffen.
                        </p>
                        <h3 className="mb-1 font-display text-xl font-bold sm:mb-2 sm:text-xl lg:text-2xl">
                            §6 » Lags
                        </h3>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            Der Server darf nicht bewusst zum <strong>Laggen</strong> oder <strong>Crashen</strong> gebracht werden.
                        </p>
                        <h3 className="mb-1 font-display text-xl font-bold sm:mb-2 sm:text-xl lg:text-2xl">
                            §7 » Bugs
                        </h3>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            Das Ausnutzen von <strong>Bugs</strong> ist nicht erlaubt.
                            Die einzige Ausnahme sind <strong>TNT-Duper</strong>.
                        </p>
                        <h3 className="mb-1 font-display text-xl font-bold sm:mb-2 sm:text-xl lg:text-2xl">
                            §8 » Entities
                        </h3>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            Am Spawn sind außer <strong>Reit- und Haustieren</strong> keine Mobs zulässig.
                        </p>
                        <h3 className="mb-1 font-display text-xl font-bold sm:mb-2 sm:text-xl lg:text-2xl">
                            §9 » Elytren
                        </h3>
                        <p className="mb-2 text-lg sm:mb-4 sm:text-xl">
                            Jeder Spieler darf maximal <strong>zwei Elytren</strong> besitzen.
                            Weder das <strong>Mitbringen</strong> für andere Spieler, noch der <strong>Verkauf</strong> von Elytren ist gestattet.
                        </p>
                    </div>
                </article>
            </main>
            <Footer />
        </>
    );
}
